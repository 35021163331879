@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  display: flex;
  flex-direction: column;
  gap: 4px;

  .item{
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 40px;
    font-weight: 500;
    font-size: 14px;
    line-height: 100%;
    letter-spacing: 0;
    cursor: pointer;
    user-select: none;
    border-radius: $borderRadiusMd;
    margin-bottom: 1px;
    color: $clr-gray-650;

    &.active{
      background-color: $clr-gray-100;
    }

    &:hover {
      background-color: $clr-white-100;
    }

    &.disabled {
      cursor: not-allowed;
      color: $clr-gray-600;
      pointer-events: none;
    }

    &:active{
      font-size: 13px;
    }
  }

}
