@import "../../../../../../styles/mixins";
@import "../../../../../../styles/fonts";
@import "../../../../../../styles/tmp";
@import "../../../../../../styles/colors";
@import "../../../../../../styles/variables";
@import "../../../../../../styles/typography";
@import "../../../../../../styles/animations";

.wrapper {
  min-width: 248px;
  .navItemLink {
    display: block;
    width: 100%;
    height: 100%;
    color: inherit;
    padding: 0 16px;
    font-size: 16px;
    line-height: 40px;
    text-align: left;
  }
  .select {
    display: block;
    @include minw($sm-tab) {
      display: none;
    }
    width: 100%;
    margin-bottom: 16px;

    :global {
      .ant-select-selector {
        background-color: $clr-white-100;

        .ant-select-selection-item {
          font-weight: 500;
        }
      }
    }
  }
  .menu {
    @include minw($sm-tab) {
      display: block;
    }
    display: none;
  }
}
