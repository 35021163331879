$widthGlobal: 1166px;
$widthSider: 184px;
$widthContent: 700px;

$rightColWidth: 250px;

$galleryThumbWidth: 88px;
$galleryThumbHeight: 66px;

$heightHeader: 56px;
$heightHeaderSm: 57px;

$siderMarginTop: $heightHeader + 13px;

$borderRadiusMd: 8px;
$borderRadiusLg: 16px;
$borderRadiusXl: 24px;
$borderRadiusProfileAvatar: 10px;
$borderRadiusFooterButton: 16px;
$borderRadiusGalleryIcon: 30px;

$resolutionsSm: 576;
$resolutionsMd: 768;
$resolutionsLg: 992;
$resolutionsXl: 1200;
$resolutionsXxl: 1400;

$sizingColWidth: 720px;
$sizingHeaderHeight: 80px;
$sizingSearchWidth: 600px;
$sizingSearchVisible: 1200px;
$sizingHeaderControlsVisible: 1199px;
$sizingFooterButton: 32px;
$sizingAside: 232px;

$modalBlur: blur(5px);
$modalBackdropColor: rgb(212 212 212 / 60%);
