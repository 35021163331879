@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.link {
  color: $clr-primary-700;
  word-break: break-all;
  &Img {
    position: relative;
    top: 3px;
    margin-right: 4px;
  }
}

.linkPreviewModal {
  &:global {
    &.ant-modal {
      position: fixed;
      top: 0;
      left: 0;
      width: 100vw;
      max-width: none;
      height: 100%;
      margin: 0;
      padding: 0;

      .ant-modal-content {
        position: absolute;
        width: 100%;
        bottom: 0;
        left: 0;
        padding: 0;
        border-radius: $borderRadiusLg $borderRadiusLg 0 0;
        background-color: $clr-gray-400;
      }
    }
  }
}

.modalImage {
  height: 190px;
  overflow: hidden;
  border-radius: $borderRadiusLg $borderRadiusLg 0 0;
  img {
    height: 190px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
}

.modalVideo {
  //height: 190px;
  overflow: hidden;
  border-radius: $borderRadiusLg $borderRadiusLg 0 0;
  img {
    height: 190px;
    width: 100%;
    object-fit: cover;
    object-position: center;
    overflow: hidden;
  }
}

.modalContent {
  padding: 12px;
}
.modalTitle {
  font-weight: 500;
  font-size: 18px;
  line-height: 20px;
  margin-bottom: 6px;
}
.modalDescription {
  font-size: 16px;
  line-height: 20px;
  color: $clr-gray-850;
}
.modalControls {
  display: flex;
  gap: 6px;
  padding: 0 12px 26px;
}
.modalButton {
  flex: 1;
}

.youtubeIframe {
  width: 100%;
  aspect-ratio: 16/9;
}
