@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.creditCard {
  :global {
    .ant-typography-copy {
      line-height: 21px !important;
    }
    .ant-typography-copy.ant-typography-copy-success {
      color: black;
    }
  }
}

.provider {
  position: relative;
  top: 2px;
}
