@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.newPostButton {
  margin-bottom: 6px;
  padding-top: 12px;
}

.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  font-size: 16px;
}

.newPostModal {
  :global {
    .ant-modal-content {
      margin: 20px 0;
      padding: 12px;
      background-color: white !important;
    }
    .ant-modal-mask {
      backdrop-filter: $modalBlur;
      background-color: $modalBackdropColor;
    }
  }
}
