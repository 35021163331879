@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  width: 100%;
  @include minw($sm-tab) {
    display: flex;
    justify-content: center;
  }
}
.bg {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  background-color: $clr-primary-900;
  border-top-left-radius: $borderRadiusLg;
  border-top-right-radius: $borderRadiusLg;
  background-image: url("/assets/images/auth.jpg");
  background-size: cover;
  background-position: top left;
  background-repeat: no-repeat;

  @include minw($sm-tab) {
    width: 500px;
    min-height: 630px;
    padding: 40px;
    border-bottom-left-radius: $borderRadiusLg;
    border-top-right-radius: 0;
  }
}
.logo {
  margin-bottom: 8px;

  svg {
    width: 40px;
    height: 20px;

    @include minw($sm-tab) {
      width: 78px;
      height: 42px;
    }
  }
}
.desc {
  &Slide {
    padding-right: 40px;
    color: white;
    @include minw($sm-tab) {
      padding-right: 100px;
    }
  }
  &Title {
    font-weight: 600;
    font-size: 18px;
    line-height: 24px;
    margin-bottom: 6px;

    @include minw($sm-tab) {
      font-size: 28px;
      line-height: 40px;
    }
  }
  &Text {
    font-size: 12px;
    line-height: 16px;

    @include minw($sm-tab) {
      font-size: 16px;
      line-height: 22px;
    }
  }
}

.title {
  font-size: 24px;
  font-weight: 500;
  line-height: 30px;
  font-style: normal;
  margin-bottom: 24px;
}

.formWrap {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 412px;
  max-width: 100%;
  padding: 20px;
  margin: 0 auto;
  @include minw($sm-tab) {
    padding: 40px 40px 16px;
  }
}
.loginCarousel {
  :global {
    .slick-dots {
      position: absolute;
      margin-top: 12px;
      top: -36px;
      bottom: auto;

      @include minw($sm-tab) {
        position: absolute;
        top: auto;
        bottom: 0;
        right: 0;
        width: auto;
        margin-top: 0;
        justify-content: flex-end;
      }

      li,
      li.slick-active {
        list-style: none;
        width: 6px;
        height: 6px;

        button {
          width: 6px;
          height: 6px;
        }
        &:before {
          display: none;
        }
        &:after {
          inset: 0;
          width: 6px;
          height: 6px !important;
          border-radius: 50%;
        }
      }
    }
  }
}

.links {
  display: flex;
  gap: 16px;
  justify-content: center;
  margin-top: auto;
}

.link {
  font-size: 10px;
  line-height: 13px;
  color: $clr-gray-750;
  white-space: nowrap;
  text-decoration: underline;

  &:hover {
    color: $clr-gray-750;
    text-decoration: none;
  }
}