@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  height: 100%;
  display: flex;
  margin: 0 auto;
  max-width: 550px;
  margin-top: 10px;
  align-items: center;
  flex-direction: column;
  justify-content: center;
}

.text {
  margin-top: 8px;
}
