@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.column {
  height: 100%;
  margin-left: auto;
  margin-right: auto;
  @include minw($sm-tab) {
    padding: 0 10px;
    width: $sizingColWidth;
  }

  @include minw($tab) {
    flex: 0 auto;
    max-width: none;
  }
  width: 100%;
}

.column.columnWithRight {
  width: 100%;
}

.columnWrapper {
  width: 100%;

  @include maxw($pc) {
    margin-left: auto;
    margin-right: auto;
  }
  @include minw($pc) {
    margin: 0 6px;
  }
}

.layoutContent {
  position: relative;
  width: 100%;
  max-width: $widthGlobal;
  margin-left: auto;
  margin-right: auto;
  display: flex;
}

:global {
  .ant-layout {
    min-height: 100%;
  }
}
