@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  height: calc(100% - 12px);

  @include minw($pc) {
    margin-right: -15px;
  }
}

.card {
  padding: 18px 12px;
  min-height: 100%;
  background-color: white;

  @include minw($sm-tab) {
    padding: 16px;
    box-shadow: $boxShadowPost;
    border-radius: $borderRadiusMd;
  }

  @include minw($pc) {
    padding: 32px;
  }

  .column {
    display: flex;
    flex-direction: column;
    max-width: 436px;
    width: 100%;
    margin: 0 auto;

    @include minw($sm-tab) {
      display: block;
      max-width: none;
      margin: 0;
    }
  }

  .content {
    width: 100%;
    display: flex;
    flex-direction: row;
    @include maxw($sm-tab) {
      flex-direction: column;
    }
  }

  .notSelectedType {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
}

.title {
  font-weight: 500;
  font-size: 24px;
  line-height: 40px;
  letter-spacing: 0;
}

.row {
  margin-bottom: 16px;
}

.subtitle {
  font-weight: 500;
  color: $clr-gray-850;
}
