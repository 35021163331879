@import "../../styles/mixins";
@import "../../styles/fonts";
@import "../../styles/tmp";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/animations";

.notifyButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:hover {
    svg {
      path {
        stroke: $clr-gray-1000;
      }
    }
  }
}

.notifyContainer {
  display: flex;
  flex-direction: column;
  gap: 8px;
  height: 100%;
  @include for-tablet-portrait-up {
    flex-direction: row;
    gap: 16px;
  }
}

.notifyColumn {
  height: 100%;
  width: 100%;
  overflow: auto;
}
.loadMoreButton {
  display: flex;
  justify-content: center;
}
.drawerWrapper {
  @include minw($sm-tab + 1) {
    border-radius: 8px;
  }
}
.drawerHeader {
  border-bottom: 1px solid $clr-gray-450 !important;
}


.drawerContainer {
  ::-webkit-scrollbar {
    width: 4px;
  }
  ::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
  }
  ::-webkit-scrollbar-thumb {
    background-color: darkgrey;
  }

  :global {
    .ant-drawer-content-wrapper {
      box-shadow: none;
      margin: 0;
      border-radius: 0;
      transition: all 0.2s;

      @include minw($sm-tab + 1) {
        margin: 8px;
        border-radius: $borderRadiusMd;
      }
    }

    .ant-drawer-header {
      //position: relative;
      border-bottom: none;
      padding-bottom: 8px;
    }

    .ant-drawer-header-title {
      flex-direction: row-reverse;
    }

    .ant-drawer-title {
      font-size: 20px;
      line-height: 28px;
      font-weight: 500;
    }

    .ant-drawer-body {
      //overflow: visible;
      padding: 0;
    }
  }
}

.drawer {
  @include minw($sm-tab + 1) {
    border: 1px solid $clr-gray-600;
  }
}


