@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.form {
  &PostWrap {
    margin-bottom: 14px;
    overflow: auto;
  }

  &Form {
    height: 100%;
    display: flex;
    flex-direction: column;
  }

  &Textarea {
    padding: 0;
    border-radius: 0;
    font-weight: 400;
    line-height: 24px;
    font-style: normal;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    min-height: 80px !important;
    margin: 0 0 16px 0 !important;
    background-color: transparent !important;
    transition: none !important;
    font-size: 18px;

    ::placeholder {
      font-size: 18px;
    }

    @include maxw($sm-tab) {
      max-height: 68dvh;
      min-height: 121px !important;
    }

    .noAttachments & {
      min-height: 200px !important;

      @include maxw($sm-tab) {
        max-height: 76dvh;
      }
    }
  }
}

.noAttachments {
  min-height: 225px;

  @include maxw($sm-tab) {
    max-height: 76dvh;
  }
}
