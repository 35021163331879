@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.wrapper {
  .title {
    font-weight: 500;
    font-size: 22px;
    line-height: 30px;
    letter-spacing: 0;

  }
  .content{
  padding: 8px 0;
  }
}
