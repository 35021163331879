@import "../../../../../styles/mixins";
@import "../../../../../styles/fonts";
@import "../../../../../styles/tmp";
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/typography";
@import "../../../../../styles/animations";

.single {
  width: fit-content;
  max-width: 100%;
  border: 1px solid #e7edf2;
  border-radius: $borderRadiusMd;
  box-shadow: $boxShadowImageGrid;
  overflow: hidden;
  cursor: pointer;

  &Expanded {
    width: 100%;
  }

  &Wrap {
    width: 100%;
    max-width: 100%;
  }

  &Image {
    width: auto;
    max-width: 100%;
    border-radius: $borderRadiusMd;
    height: auto;

    &HasCut {
      width: auto;
      max-height: 360px;

      @include minw($mob) {
        height: 400px;
      }

      @include minw($lg-mob) {
        height: 500px;
      }

      @include minw($pc) {
        height: 640px;
      }
    }

    &Comment {
      max-height: 280px;
    }
  }
}

.gridFooter {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;

  &:after {
    left: 0;
    bottom: 17.5px;
    z-index: 0;
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    background-color: $clr-primary-100;
  }

  &HasCut {
    margin-top: 14px;
  }
}

.skeleton {
  min-width: 334px;
  max-width: 100%;
  min-height: 208px;
}
