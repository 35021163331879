@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.modal {
  &Post {
    &Avatar {
      width: 40px;
      height: 40px;
      border-radius: 6px;
    }

    &Header {
      display: flex;
      flex-direction: column;

      @media (min-width: #{$resolutionsMd}px) {
        gap: 8px;
        flex-direction: row;
      }
    }

    &Info {
      gap: 12px;
      min-width: 0;
      display: flex;
      align-items: center;

      &Body {
        gap: 2px;
        display: flex;
        flex-direction: column;

        @media (min-width: 769px) {
          width: 700px;
          max-width: 100%;
        }
      }
    }

    &Author {
      font-size: 14px;
      font-weight: 700;
      line-height: 18px;
      font-style: normal;
      color: $clr-white-500;
      white-space: nowrap;

      &:hover {
        color: $clr-primary-500;
      }
    }

    &Date {
      font-size: 14px;
      font-weight: 400;
      line-height: 18px;
      font-style: normal;
      color: $clr-white-500;
    }

    &Text {
      width: 100%;
      min-width: 0;
      display: none;
      max-width: 100%;
      font-size: 14px !important;
      font-weight: 400;
      font-style: normal;
      line-height: 18px;
      color: $clr-white-500;
      margin-bottom: 0 !important;

      @media (min-width: 769px) {
        display: block;
      }

      * {
        color: white !important;
      }

      :global {
        .ant-typography {
          font-size: 14px;
        }
      }
    }
  }
}
