@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.action {
  &Button {
    height: $sizingFooterButton;
    font-size: 14px;
    align-items: center;
    color: $clr-gray-800;
    background-color: $clr-gray-400;
    border-radius: $borderRadiusFooterButton;
  }

  &Label {
    font-weight: 500;
    user-select: none;
    padding: 0 12px 0 8px;
  }

  &MainIcon {
    width: $sizingFooterButton;
    height: $sizingFooterButton;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    background-color: $clr-gray-350;
    border-radius: $borderRadiusFooterButton;
  }

  &Icon {
    user-select: none;
    width: $sizingFooterButton;
    height: $sizingFooterButton;
    display: flex;
    cursor: pointer;
    align-items: center;
    border-radius: $borderRadiusFooterButton;
    justify-content: center;

    &:hover {
      background-color: $clr-primary-100;
    }
  }
}
.wrapper {
  user-select: none;
  gap: 2px;
  display: flex;
}
