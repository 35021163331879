@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.reportModal {
  margin-bottom: 32px;

  .ant-modal-content {
    background-color: white;
  }

  &Wrap {
    background-color: white;
    border-radius: $borderRadiusMd;
    :global {
      .ant-modal-mask {
        backdrop-filter: $modalBlur;
        background-color: $modalBackdropColor;
      }
    }
  }
  &Title {
    font-size: 16px;
    line-height: 22px;
    margin-bottom: 24px;
  }
  &Item {
    width: 100%;
    align-items: flex-start !important;
    :global .ant-radio {
      align-self: flex-start;
      margin-top: 4px;
    }
    :global span:last-child {
      flex: 1;
    }
  }
  &ItemLabel {
    flex: 1;
    width: 100%;
  }
  &ItemTitle {
    font-size: 18px;
    line-height: normal;
  }
  &ItemText {
    font-size: 14px;
    font-style: normal;
  }
  &ItemTextarea {
    border-radius: $borderRadiusMd;
    border: 1px solid $clr-gray-350;
    background-color: $clr-gray-200;
    color: $clr-gray-1000;
    padding: 10px;
    height: 48px;
    min-height: 42px !important;
    &:focus {
      border-color: $clr-gray-350;
      box-shadow: none;
    }
    &::placeholder {
      color: $clr-gray-850;
    }
  }
  &Divider {
    margin: 8px 0;
  }
  &CheckboxName {
    font-weight: 500;
  }
}
