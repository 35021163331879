@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.wrapper {
  width: 580px;
  max-width: calc(100vw - 38px);
  height: 88px;
  display: flex;
  align-items: stretch;
  justify-content: flex-start;
  cursor: pointer;
  .body {
    flex: 1;
    padding: 12px 14px;
    max-width: 100%;
    overflow: hidden;
  }
  .title {
    margin-bottom: 6px;
    white-space: nowrap;
    text-overflow: ellipsis;
    color: $clr-black-500;
    font-size: 16px;
    font-weight: 500;
    line-height: 20px;
  }
  .description {
    margin-bottom: 0;
    overflow: hidden;
    color: $clr-gray-750;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
  }
  .image {
    flex: 0 140px;
    width: 140px;
    height: 88px;

    img {
      width: 140px;
      height: 88px;
      object-fit: cover;
      object-position: center;
      border-top-left-radius: $borderRadiusMd;
      border-bottom-left-radius: $borderRadiusMd;
      overflow: hidden;
    }
  }
}
.youtubeIframe {
  width: 100%;
  aspect-ratio: 16/9;
}

.textLink{
 word-break: break-all;
}

.link {
  cursor: pointer;
  color: $clr-primary-700;
  line-height: 21px;

  &Img {
    position: relative;
    top: 3px;
    margin-right: 4px;
  }
}
