@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";


.wrapper {
  width: 40px;
  height: 40px;

  .avatar {
    width: 40px;
    border-radius: 8px;
  }

  .badge {
    position: relative;
    bottom: 15px;
    right: -20px;
    width: 24px;
    height: 24px;
    border-radius: 20px;
    background-color: #FFFFFF;
    z-index: 2;

    display: flex;
    justify-content: center;
    align-items: center;
  }
}

