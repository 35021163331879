@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  display: flex;
  flex-direction: column;
  padding-left: 20px;
}

.list {
  margin-top: 24px;
  padding: 0 0 14px;
}

.link {
  color: $clr-gray-800;
  font-size: 12px;
  line-height: 20px;
}
