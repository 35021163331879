@import "../../styles/mixins";
@import "../../styles/fonts";
@import "../../styles/tmp";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/animations";

.error {
  &Wrap {
    display: flex;
    width: 100vw;
    min-height: 100vh;
    align-items: center;
    justify-content: center;
    background-color: $clr-gray-300;
  }

  &Box {
    gap: 32px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: center;
  }

  &Title {
    font-size: 24px;
    line-height: 32px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &Img {
    width: 400px;
    max-width: 100%;
  }
}
