@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  @include maxw($sm-tab) {
    overflow-x: auto;
  }
}

.attachments {
  margin: 0;
  display: grid;
  row-gap: 8px;
  column-gap: 8px;
  grid-template-columns: repeat(2, 1fr);

  @include minw(#{$resolutionsSm}px) {
    grid-template-columns: repeat(3, 1fr);
  }

  @include minw(#{$resolutionsMd}px) {
    grid-template-columns: repeat(4, 1fr);
  }

  @include minw(#{$resolutionsLg}px) {
    grid-template-columns: repeat(5, 1fr);
  }

  @include maxw($sm-tab) {
    display: flex;
    gap: 8px;
    flex-wrap: nowrap;
    width: fit-content;
  }
}
