@import "../../styles/mixins";
@import "../../styles/fonts";
@import "../../styles/tmp";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/animations";

.card {
  background: white;
  box-shadow: $boxShadowLg;
  padding: 16px;
  font-size: 14px;
  line-height: 22px;

  @include minw($sm-tab) {
    border-radius: $borderRadiusMd;
    margin-bottom: 20px;
    padding: 24px;
  }
}

.title {
  font-size: 22px;
  font-weight: 600;
  line-height: 24px;
  font-style: normal;
  color: #000000;
}

.subtitle {
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  color: #000000;
}

.ul {
  padding-left: 30px;
}
