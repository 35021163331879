@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.popover {
  width: 346px;
  overflow: hidden;
  border-radius: 7px;
  background-color: $clr-white-500;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);

  &Content {
    padding: 10px 16px;
    margin-top: -42px;
    flex-direction: row;
    align-items: flex-end;
    .avatarContainer {
      border-radius: 6px;
      border: 4px solid $clr-white-500;
      background-color: white;
    }
  }

  &Name {
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    color: $clr-black-800;
    margin-bottom: 4px;
  }

  &Username {
    font-size: 14px;
    font-weight: 400;
    line-height: 18px;
    color: $clr-gray-750;
    margin-bottom: 6px;
  }

  &Bio {
    padding: 0 16px 16px;
    user-select: none;

    &Text {
      font-size: 16px;
      line-height: 22px;
      font-weight: 400;
      color: $clr-gray-750;
      margin-bottom: 0 !important;
    }
  }

  &Buttons {
    width: 100%;
    margin-top: 10px;
  }
}

.avatar {
  width: 90px;
  height: 90px;
  display: block;
  border-radius: 6px;
  border: 4px solid $clr-white-500;
  overflow: hidden;
  margin-left: -3px;
}
