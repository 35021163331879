@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.threadComment {
  &Wrap {
    position: relative;
    padding-right: 16px;
  }

  &Box {
    position: relative;
  }
}

.removed {
  display: flex;
  align-items: center;
  .commentBody {
    margin: 0 0 0 18px;
    color: $clr-gray-750;
    font-size: 16px;
    line-height: 22px;
  }
}
.removedDot {
  width: 4px;
  height: 4px;
  border-radius: 50%;
  background: $clr-gray-700;
  margin-left: 18.5px;
}

.secondLevelLine {
  top: 44px;
  left: 20px;
  content: "";
  width: 0.5px;
  position: absolute;
  height: calc(100% - 36px);
  background-color: $clr-gray-700;
}

.comment {
  &Header {
    line-height: 20px;
    font-style: normal;
    font-size: $fontSizeBase;
    white-space: nowrap;
    flex-wrap: wrap;

    &::before {
      position: absolute;
      top: 18px;
      left: -13px;
      content: "";
      width: 4px;
      height: 4px;
      border-radius: 50%;
      background: #cccccc;

      @include minw($sm-tab) {
        left: -28px;
      }
    }
  }

  &Avatar {
    height: 40px;
    width: 40px;
    border-radius: $borderRadiusMd;
  }

  &Author {
    font-size: $fontSizeSm;
    line-height: 16px;
    font-weight: 500;
    color: $clr-gray-900;
    white-space: nowrap;
    margin-right: 2px;

    &:hover {
      color: $clr-primary-500;
    }
  }

  &Delimiter {
    color: $clr-gray-700;
  }

  &ReplyTo {
    font-size: $fontSizeSm;
    line-height: 16px;
    font-weight: 400;
    color: $clr-gray-750;
  }

  &ReplyToHidden {
    font-size: $fontSizeSm;
    line-height: 16px;
    font-weight: 400;
    color: $clr-gray-750;
    cursor: not-allowed;
  }

  &DateTime {
    font-size: $fontSizeSm;
    line-height: 16px;
    color: $clr-gray-750;
    white-space: nowrap;
  }

  &HiddenComment {
    font-size: 14px;
    line-height: 22px;
    cursor: not-allowed;
  }

  &Tooltip {
    :global {
      .ant-tooltip-inner {
        color: $clr-gray-1000 !important;
        white-space: nowrap !important;
      }
    }
  }

  &Body {
    margin: 2px 0 4px 0;
    font-weight: 400;
    font-style: normal;
    font-size: $fontSizeBase;
    line-height: 1.43;
    white-space: break-spaces;
    word-break: break-word;
  }
  &Actions {
    margin-left: -2px;
    margin-top: 4px;
  }
}
