@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.wrapper {
  @include maxw($sm-tab) {
    :global {
      .ant-modal {
        top: auto !important;
        bottom: 0 !important;
        padding-bottom: 0 !important;
        margin-bottom: 0 !important;
        vertical-align: bottom !important;
        max-width: 100% !important;
        max-height: 96vh;
      }
      .ant-modal-content {
        margin-bottom: 0 !important;
        border-radius: $borderRadiusLg $borderRadiusLg 0 0 !important;
        max-height: 96dvh;
      }
      .ant-modal-body {
        height: 100%;
      }

      .post-form {
        height: 100%;
        display: flex;
        flex-direction: column;
      }
    }
  }
}