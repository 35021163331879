@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.readMore {
  user-select: none;
  z-index: 1;
  display: flex;
  font-weight: 500;
  text-align: right;
  position: relative;
  padding: 8px 0 0 12px;
  align-items: center;
  justify-content: flex-end;

  &:before {
    left: 0;
    bottom: 0;
    z-index: 0;
    width: 100%;
    content: "";
    height: 240%;
    position: absolute;
    background: linear-gradient(
                    to bottom,
                    rgba($clr-white-500, 0) 0%,
                    rgba($clr-white-500, 1) 100%
    );
  }

  &:after {
    left: 0;
    bottom: 17.5px;
    z-index: 1;
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    background-color: $clr-primary-100;
  }

  &Span {
    height: 36px;
    gap: 6px;
    z-index: 3;
    display: flex;
    font-size: 16px;
    font-weight: 500;
    line-height: 1;
    position: relative;
    align-items: center;
    padding: 0 16px;
    border-radius: $borderRadiusXl;
    color: $clr-primary-500;
    background-color: white;
    border: 1px solid $clr-gray-500;
    transition: all 0.2s ease-in;
    cursor: pointer;

    svg {
      path {
        transition: all 0.2s ease-in;
      }
    }

    &:hover {
      background-color: $clr-primary-500;
      border-color: $clr-primary-500;
      color: white;
      transition: all 0.2s ease-in;
      svg {
        path {
          transition: all 0.2s ease-in;
          stroke: $clr-white-500;
        }
      }
    }

    &:before {
      position: absolute;
      left: -9px;
      width: 8px;
      height: 100%;
      background-color: white;
      content: "";
    }
  }
} 