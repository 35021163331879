@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  gap: 8px;
  display: flex;
  flex-direction: column;
  width: 100%;

  @include minw(#{$resolutionsMd}px) {
    gap: 16px;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .settings {
    display: flex;
    gap: 8px;

    @include minw($sm-mob) {
      gap: 12px;
    }

    .button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $clr-gray-600;
      background-color: #feffff;
      padding: 0;

      svg {
        width: 20px;
      }
    }

    .buttonPrimary {
      background-color: $clr-primary-700;
    }

    .icon {
      transition: 0.2s ease-in-out;

      &.paperClip {
        fill: $clr-gray-800;
      }

      &.postLock {
      }

      &.messageQuestion {
        stroke: $clr-gray-800;
      }
    }
  }

  .buttons {
    gap: 8px;
    display: flex;

    .item {
      flex: 1;
      @include minw($sm-mob) {
        flex: auto;
        width: 100%;
      }
    }

    @include minw(#{$resolutionsMd}px) {
      gap: 16px;
      flex-direction: row;
    }
  }
}
