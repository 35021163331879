@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.column {
  display: none;
  flex: 0 0 $rightColWidth;

  @include minw($pc) {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
  .content {
    position: sticky !important;
    top: $siderMarginTop;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }
}

.widget {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  text-align: center;
  font-size: 16px;
  line-height: 22px;
  overflow: hidden;
  gap: 16px;
  > img {
    width: $rightColWidth;
    max-width: 100%;
  }
}

.image {
  width: 100%;
  height: auto;
  border-radius: $borderRadiusMd;
}

.reportButton {
  width: 100%;
  height: 40px;
  border-radius: 12px;
  font-size: 16px;
  color: $clr-primary-500;
  background-color: $clr-white-500;
  border:1px solid $clr-gray-500;
  &:hover {
    color: $clr-primary-500 !important;
    border-color: #6F6AF5 !important;
    background-color: #F6F8FC !important;
  }
  > div {
    display: none;
  }
}

.link {
  width: 100%;
}
