@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  position: relative;
  margin-bottom: 12px;
}

.commentForm {
  height: 46px;
  line-height: 1 !important;
  border: 2px solid transparent;
  background-color: $clr-gray-400;
  font-size: 18px;
  padding: 10px 8px;
  border-radius: 8px;

  @include maxw($sm-tab) {
    height: 40px !important;
    padding: 7px 10px;
    margin-bottom: 6px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $clr-gray-400;
    outline: none;
    box-shadow: none;
  }
}

.formSubmit {
  right: 0;
  bottom: 0;
  color: $clr-gray-900;
  position: absolute;
}

.form {
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &PostWrap {
    margin-bottom: 14px;
    overflow: auto;
  }

  &Textarea {
    padding: 0;
    border-radius: 0;
    font-weight: 400;
    line-height: 24px !important;
    font-style: normal;
    border: none !important;
    outline: none !important;
    box-shadow: none !important;
    min-height: 72px !important;
    margin: 0 0 16px 0 !important;
    background-color: transparent !important;
    transition: none !important;
    font-size: 18px;

    ::placeholder {
      font-size: 18px;
    }

    @include maxw($sm-tab) {
      max-height: 68dvh;
      min-height: 112px !important;
    }

    .noAttachments & {
      min-height: 50px !important;

      @include maxw($sm-tab) {
        max-height: 76dvh;
      }
    }
  }
}

.desktopCommentWrapper {
  margin-top: 10px;
  background: #f6f8fc;
  padding: 8px;
  border-radius: 8px;
}

.noMargin {
  margin-top: 0;
}
