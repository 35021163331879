@import "../../../../../styles/mixins";
@import "../../../../../styles/fonts";
@import "../../../../../styles/tmp";
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/typography";
@import "../../../../../styles/animations";

.imageMore {
  gap: 6px;
  z-index: 1;
  display: flex;
  height: 36px;
  font-size: 16px;
  line-height: 1;
  font-weight: 500;
  position: relative;
  align-items: center;
  padding: 0 16px;
  border-radius: $borderRadiusXl;
  color: $clr-primary-500;
  background-color: white;
  border: 1px solid $clr-gray-500;
  transition: all 0.2s ease-in;
  cursor: pointer;

  svg {
    path {
      transition: all 0.2s ease-in;
    }
  }

  &:hover {
    background-color: $clr-primary-500;
    border-color: $clr-primary-500;
    color: white;
    transition: all 0.2s ease-in;
    svg {
      path {
        transition: all 0.2s ease-in;
        stroke: $clr-white-500;
      }
    }
  }

  &:before {
    position: absolute;
    left: -9px;
    width: 8px;
    height: 100%;
    background-color: white;
    content: "";
  }
}

.imageMoreLabel {
  font-size: 16px;
  line-height: normal;
  font-weight: 500;
}