@import "../../../../../styles/mixins";
@import "../../../../../styles/fonts";
@import "../../../../../styles/tmp";
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/typography";
@import "../../../../../styles/animations";

.profile {
  &Subscribers {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &Count {
    font-size: 14px;
    line-height: 18px;
    color: $clr-gray-800;

    strong {
      font-weight: 700;
    }

    &:last-child {
      padding-left: 8px;
      border-left: 1px solid $clr-gray-600;
    }
  }
}
