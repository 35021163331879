@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.loginModal {
  :global {
    .ant-modal {
      @include maxw($sm-tab) {
        top: 16px;
      }
    }

    .ant-modal-content {
      background-color: white;
      padding: 0;
      border-radius: $borderRadiusLg;
    }

    .ant-modal-close-x {
      color: white;

      @include minw($sm-tab) {
        color: rgba(0, 0, 0, 0.45);
      }
    }

    .ant-modal-mask {
      backdrop-filter: $modalBlur;
      background-color: $modalBackdropColor;
    }
  }
}
