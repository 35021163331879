@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.formButton {
  font-weight: 700;
  height: auto !important;
  padding: 11px 16px !important;
}

.styledLink {
  line-height: 1.6;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  text-align: center;
  text-decoration: none;
  color: $clr-primary-500;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
