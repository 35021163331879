@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  text-align: center;
  padding-top: 65px;
  .image {
    width: 204px;
  }
  .text {
    padding-top: 8px;
    font-size: 16px;
    font-weight: 400;
  }
}
