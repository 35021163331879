@import "../../../../../styles/mixins";
@import "../../../../../styles/fonts";
@import "../../../../../styles/tmp";
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/typography";
@import "../../../../../styles/animations";

.wrapper {
    display: flex;
    flex-direction: column;
    gap: 8px;

    align-items: flex-start;

    .success {
        margin-top: 16px;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        gap: 8px;
        background-color: $clr-white-100;
        padding: 40px 20px;
        border-radius: $borderRadiusLg;
    }

    .button {
        margin-top: 10px;
    }

    .error {
        color: red;
    }
}
