// === COMMON ===
@mixin hover($pointer) {
  @media only screen and (hover: hover) and (pointer: $pointer) {
    @content;
  }
}

@mixin orientation($orientation) {
  @media screen and (orientation: $orientation) {
    @content;
  }
}

@mixin content-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

// === NEW APPROACH SIZES ===
$baseRem: 16;
$breakpoints: (
  "xl": 1920,
  "lg-xl": 1600,
  "lg": 1440,
  "md": 1366,
  "pc": 1200,
  "sm-pc": 1140,
  "lg-tab": 1024,
  "tab": 968,
  "sm-tab": 768,
  "lg-mob": 568,
  "mob": 414,
  "sm-mob": 375,
  "xs-mob": 320,
);

@mixin mq($width, $type: min) {
  @if map_has_key($breakpoints, $width) {
    $width: map_get($breakpoints, $width);
    $em: if($type==min, $width / $baseRem + "em", ($width - 1) / $baseRem + em);
    @media only screen and (#{$type}-width: $em) {
      @content;
    }
  }
}

// === OLD APPROACH SIZES ===
$xl: 1920px;
$lg-xl: 1600px;
$lg: 1440px;
$pc: 1200px;
$sm-pc: 1140px;
$lg-tab: 1024px;
$tab: 968px;
$md-tab: 820px;
$sm-tab: 768px;
$lg-mob: 767px;
$mob: 414px;
$sm-mob: 375px;
$xs-mob: 321px;

@mixin maxw($media) {
  @media only screen and (max-width: #{$media - 1}) {
    @content;
  }
}

@mixin minw($media) {
  @media only screen and (min-width: $media) {
    @content;
  }
}

@mixin minh($media) {
  @media only screen and (min-height: $media) {
    @content;
  }
}

@mixin maxh($media) {
  @media only screen and (max-height: #{$media - 1}) {
    @content;
  }
}

@mixin for-phone-only {
  @media (max-width: $lg-mob) {
    @content;
  }
}
@mixin for-tablet-portrait-up {
  @media (min-width: $sm-tab) {
    @content;
  }
}
@mixin for-tablet-landscape-up {
  @media (min-width: $lg-tab) {
    @content;
  }
}
@mixin for-desktop-up {
  @media (min-width: $sm-pc) {
    @content;
  }
}
@mixin for-big-desktop-up {
  @media (min-width: $lg-xl) {
    @content;
  }
}
