@import "../../../../../styles/mixins";
@import "../../../../../styles/fonts";
@import "../../../../../styles/tmp";
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/typography";
@import "../../../../../styles/animations";

.wrapper {
  width: 100%;
  overflow: hidden;
  object-fit: contain;
  aspect-ratio: 3.382;

  img,
  svg {
    width: 100%;
    @include minw($sm-tab) {
      border-top-left-radius: $borderRadiusMd;
      border-top-right-radius: $borderRadiusMd;
    }
  }

  @include minw($sm-tab) {
    border-top-left-radius: $borderRadiusMd;
    border-top-right-radius: $borderRadiusMd;
  }
}
