@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.title {
  font-size: 16px;
  font-weight: 400;
  line-height: 22px;
  text-align: center;
  color: $clr-gray-650;
}

.wrapper {
  gap: 10px;
  display: flex;
  margin-top: 20px;
  align-items: center;
  flex-direction: column;
}
