@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.longreadBar {
  bottom: 0;
  display: flex;
  margin: 0 -12px;
  position: sticky;
  padding: 18px 16px;
  color: $clr-primary-500;
  justify-content: space-between;
  background-color: $clr-white-500;
  user-select: none;
  animation: fadeIn 0.2s ease-in-out;

  a, .navigationButton {
    display: flex;
    align-items: center;
  }

  .navigationButton {
    background: none;
    border: none;
    padding: 0;
    color: $clr-primary-500;
    font-size: inherit;
    font-family: inherit;
    cursor: pointer;
    display: flex;
    align-items: center;
  }

  span {
    cursor: pointer;
    font-weight: 600;
    position: relative;
  }

  &Progress {
    left: 0;
    bottom: 0;
    height: 3px;
    position: absolute;
    background-color: $clr-primary-500;
    transition: width 0.2s ease-out;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
} 