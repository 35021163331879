@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.button {
  margin-right: 16px;
  cursor: pointer;

  @include minw($pc) {
    display: none;
  }
}
