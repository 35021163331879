@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.wrapper {
  width: 100%;

  @include minw($sm-tab) {
    gap: 8px;
  }

  :global {
    .actions-button {
      width: 100%;
    }
  }
}

.button {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4px;
}

.label {
  display: flex !important;
  align-items: center;
  justify-content: center;
  gap: 4px;
  font-size: 16px;
  line-height: 20px;
  font-weight: 500;
}

.follow {
  flex: 1;
  display: block;
}

.dropdown {
  display: block;
}