@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.action {
  &Button {
    height: 32px;
    min-width: 32px;
    font-size: 14px;
    align-items: center;
    color: $clr-gray-800;
    background-color: $clr-gray-400;
    border-radius: $borderRadiusFooterButton;
    cursor: pointer;
  }

  &Icon {
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    color: $clr-gray-800;
    justify-content: center;
    background-color: $clr-gray-350;
    border-radius: $borderRadiusFooterButton;

    > svg {
      width: auto;
      height: auto;
      max-width: 18px;
      max-height: 18px;
    }

    &:hover {
      background-color: $clr-primary-100;
    }
  }

  &IconText {
    min-width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    color: $clr-gray-800;
    justify-content: center;
    background-color: $clr-gray-350;
    border-radius: $borderRadiusFooterButton;
    padding: 0 12px;
    &:hover {
      background-color: $clr-primary-100;
    }
  }

  &Label {
    padding: 0 12px 0 8px;
    user-select: none;
  }
}
