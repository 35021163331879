@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.removeModal {
  :global {
    .ant-modal-mask {
      backdrop-filter: $modalBlur;
      background-color: $modalBackdropColor;
    }
  }
}
