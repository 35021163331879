@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.header {
  position: sticky;
  top: 0;
  z-index: 3;
  background-color: white;
  border-bottom: 1px solid $clr-gray-450;

  @include minw($sm-tab) {
    margin-bottom: 12px;
  }
}

.wrap {
  width: 100%;
  max-width: $widthGlobal;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 16px;
  padding: 8px;
  height: $heightHeader;

  @include minw($pc) {
    padding-left: 0;
    padding-right: 0;
  }
}

.stickyHeader {
  position: sticky;
  animation: panelsSlideDown 0.5s ease-out;
}

.logoContainer {
  display: flex;
  align-items: center;

  @include minw($pc) {
    flex: 0 0 $widthSider;
  }
}

.controlsContainer {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 4px;
  @include minw($sm-tab) {
    gap: 16px;
  }
}

.controlsIcons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 8px;
  @include minw($sm-tab) {
    gap: 16px;
  }
}

.signInContainer {
  display: flex;
  flex: 1;
  justify-content: flex-end;
  align-items: center;

  @include maxw($pc) {
    margin-right: 4px;
  }
}

.darkModeButton {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  &:hover {
    svg {
      path {
        stroke: $clr-gray-1000;
      }
    }
  }
}

.headerAvatar {

}

.profileButton {
  cursor: pointer;

  img {
    height: 40px;
    width: 40px;
    border-radius: $borderRadiusMd;
  }
}
