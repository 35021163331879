@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.form {
  &Label {
    font-size: 10px;
    margin-top: 4px;
    font-weight: 400;
    font-style: normal;
    line-height: normal;
    color: $clr-gray-800;
    letter-spacing: -0.1px;
  }

  &Button {
    font-weight: 700;
    height: auto !important;
    padding: 11px 16px !important;
  }
}

:global {
  .ant-input-prefix {
    color: $clr-gray-700;
    margin-inline-end: 0 !important;
  }
}
