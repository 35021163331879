@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.profileDesc {
  font-weight: 400;
  font-style: normal;
  font-size: $fontSizeBase;
  margin-bottom: 0 !important;
  padding: 0 $cardPadding $cardPadding;
  white-space: break-spaces;
}

.formPostWrap {
  margin: 12px 16px;
  padding: 8px 16px;
  border-radius: 8px;
  background-color: $clr-gray-400;
  border: 1px solid $clr-primary-100;
}

.formTextarea {
  padding: 0;
  font-size: 16px;
  border-radius: 0;
  font-weight: 400;
  line-height: 24px;
  font-style: normal;
  margin: 0 !important;
  border: none !important;
  outline: none !important;
  box-shadow: none !important;
  min-height: 60px !important;
  background-color: transparent !important;
}

.bioSaveButtons {
  gap: 16px;
  display: flex;
  padding: 0 16px 12px 16px;
  justify-content: flex-end;
}
