@import "../../styles/mixins";
@import "../../styles/fonts";
@import "../../styles/tmp";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/animations";

.column {
  gap: 1px;
  max-width: 100%;
  @media (min-width: 768px) {
    gap: 10px;
  }
}
