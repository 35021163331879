@import "../../styles/mixins";
@import "../../styles/fonts";
@import "../../styles/tmp";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/animations";

.wrapper {
  padding: 120px 0;
  width: 100%;
  height: 100%;
  text-align: center;
  .errorNumber {
    font-size: 80px;
    font-weight: 700;
    text-align: center;
    margin-bottom: 20px;
  }

  .title {
    font-size: 36px;
    font-weight: 500;
    text-align: center;
    margin-bottom: 20px;
  }

  .subTitle {
    font-size: 16px;
    font-weight: 400;
    text-align: center;
    margin-bottom: 32px;
  }

  .image {
    //margin: 0 auto;
    width: 100%;
  }

  .mainButton {
    width: 128px;
    margin-bottom: 60px;
  }
}
