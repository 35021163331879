@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.thread {
  &Wrap {
    position: relative;

    &:last-child {
      margin-bottom: 8px;
    }
  }

  &CommentsWrap {
    position: relative;
    margin-left: 23px;

    @include minw($sm-tab) {
      margin-left: 58px;
    }
  }

  &MoreComments {
    font-size: 14px;
    cursor: pointer;
    color: $clr-primary-500;

    strong {
      font-weight: 500;
    }
  }
}

.btn {
  &Collapse {
    left: 22px;
    width: 14px;
    height: 14px;
    bottom: 12px;
    cursor: pointer;
    position: absolute;

    &.isExpanded {
      bottom: -36px;
    }

    svg {
      fill: $clr-white-500;

      &:hover {
        path {
          stroke: $clr-primary-500;
        }
      }
    }

    @include minw($sm-tab) {
      left: 22px;
    }
  }

  &Expand {
    width: 20px;
    height: 20px;
    cursor: pointer;

    svg {
      fill: $clr-white-500;

      &:hover {
        path {
          stroke: $clr-primary-500;
        }
      }
    }
  }
}

.first {
  &Wrap {
    padding: 0 12px;
    position: relative;
  }

  &LevelLine {
    top: 44px;
    left: 32px;
    content: "";
    width: 0.5px;
    position: absolute;
    height: calc(100% - 66px);
    background-color: $clr-gray-700;

    &.isExpanded {
      height: calc(100% - 18px);
    }

    @include minw($sm-tab) {
      left: 32px;
    }
  }
}

.moreWrap {
  position: relative;
  margin-top: -4px;
  margin-left: 34px;
  gap: 16px;

  @include minw($sm-tab) {
    margin-left: 68px;
    gap: 18px;
  }
}

.comment {
  &Header {
    line-height: 20px;
    font-style: normal;
    font-size: $fontSizeBase;
    flex-wrap: wrap;
  }

  &Avatar {
    height: 40px;
    width: 40px;
    border-radius: $borderRadiusMd;
  }

  &Author {
    font-size: $fontSizeSm;
    font-weight: 500;
    line-height: 16px;
    color: $clr-gray-900;
    white-space: nowrap;
    margin-right: 2px;

    &:hover {
      color: $clr-primary-500;
    }
  }

  &AuthorDeleted {
    font-size: $fontSizeSm;
    font-weight: 500;
    line-height: 16px;
    color: $clr-gray-900;
    white-space: nowrap;
    margin-right: 2px;
    cursor: not-allowed;
  }

  &Delimiter {
    color: $clr-gray-700;
  }

  &DateTime {
    font-size: $fontSizeSm;
    line-height: 16px;
    color: $clr-gray-750;
    white-space: nowrap;
  }

  &Tooltip {
    :global {
      .ant-tooltip-inner {
        color: $clr-gray-1000 !important;
        white-space: nowrap !important;
      }
    }
  }

  &Body {
    font-weight: 400;
    font-style: normal;
    font-size: $fontSizeBase;
    line-height: 1.43;
    margin: 2px 0 4px 0;
    white-space: break-spaces;
    word-break: break-word;
  }
  &Actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-left: -2px;
    margin-top: 4px;
  }
}

.deleted {
  color: $clr-gray-750;
  height: 38px;
  line-height: 38px;
}

.disabledActions {
  pointer-events: none;
  opacity: 0.5;
}