@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

:global {
  .ant-layout-sider.ant-layout-sider-custom:not(.ant-layout-sider-collapsed) {
    background-color: transparent;
    height: calc(100vh - $heightHeaderSm) !important;
    transition: none !important;

    @include minw($pc) {
      height: calc(100vh - $siderMarginTop) !important;
    }

    .ant-layout-sider-children {
      overflow-x: hidden;
      overflow-y: auto;
    }

    @include maxw($pc) {
      background-color: white;
      box-shadow: $boxShadowSider;
      width: 232px !important;
      flex: 0 0 232px !important;
      max-width: 232px !important;
      padding: 12px;
    }
  }
}

.siderCustom {
  position: fixed !important;
  top: 57px;
  width: $widthSider;
  z-index: 2;

  @include minw($pc) {
    position: sticky !important;
    top: $siderMarginTop;
    width: $widthSider;
  }
}

.siderColumn {
  min-height: 100%;
  display: flex;
  flex-direction: column;
}

.siderMenus {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
