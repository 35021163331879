@import "../../styles/mixins";
@import "../../styles/fonts";
@import "../../styles/tmp";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/animations";

.wrapper {
  height: 100%;
  width:  436px;
  margin: 50px auto;
  text-align: center;
}
