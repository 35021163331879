@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.errorMsg {
  gap: 4px;
  display: flex;
  font-size: 10px;
  margin-top: 4px;
  font-weight: 400;
  font-style: normal;
  color: $clr-red-500;
  align-items: center;
  line-height: normal;
  justify-content: flex-start;
  letter-spacing: -0.1px;
}
