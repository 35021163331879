@import "../../styles/mixins";
@import "../../styles/fonts";
@import "../../styles/tmp";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/animations";

.feedWrap {
  min-height: 100%;
  @include minw($sm-tab) {
    padding: 0 0 16px;
  }
}

.banner {
  display: none;
  margin-bottom: 10px;

  img {
    width: 100%;
  }

  @include minw($sm-tab) {
    display: block;
  }
}

.feedLoader {
  display: flex;
  justify-content: center;
  padding: 8px 0;
}
