#root {
  height: 100%;
}

body {
  margin: 0;
  font-family: "Road UI", "Segoe UI", "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-y: scroll;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important;
}

.ril__toolbar {
  position: absolute;
  top: auto !important;
  right: 20px;
  bottom: 20px !important;
  left: auto !important;
  width: auto;
  display: block;
  border-radius: 20px;
  padding: 0 14px;
}

.ril__toolbarLeftSide {
  display: none !important;
}

.ril__toolbarRightSide {
  padding: 0 !important;
}

.ant-skeleton-image,
.ant-skeleton-element {
  width: 100% !important;
  height: 100% !important;
}

.new-post-modal {
  .ant-modal {
    padding: 0 4px;

    .ant-modal-content {
      padding: 14px;
      background-color: white;
    }
  }
}

.remove-modal {
  min-width: clamp(300px, 50%, 513px);

  .ant-modal-content {
    width: 100%;
    padding: 24px;

    .ant-modal-header {
      margin-bottom: 6px;

      .ant-modal-title {
        font-family: "Segoe UI", sans-serif;
        font-size: 22px;
        font-weight: 500;
        line-height: 33px;
      }
    }

    .ant-modal-body .description {
      font-family: "Segoe UI", sans-serif;
      font-size: 16px;
      font-weight: 400;
      line-height: 22px;
      color: #1c1c1c;
      margin-bottom: 0;
    }

    .ant-modal-footer {
      margin-top: 44px;

      .ant-btn {
        width: clamp(100px, 40%, 148px);
        height: 48px;
      }

      & > .ant-btn + .ant-btn {
        margin-inline-start: 16px;
      }
    }
  }
}

.thumbs-slider {
  .slick-list {
    padding: 4px 0 !important;
  }
}

.profile-images-modal .ant-modal-close {
  top: 20px;
  right: 20px;
  width: 32px;
  height: 32px;
}

.profile-images-modal .ant-modal-close-x {
  font-size: 20px;
  color: #2a2a2a;
}

.profile-avatar-uploader .ant-upload-select {
  width: 160px !important;
  height: 160px !important;
  background-color: white !important;
  border-width: 2px !important;
}

.profile-bg-uploader {
  width: 100%;
  padding-top: 0;
  display: block !important;
}

.profile-bg-uploader .ant-upload-select {
  display: block;
  width: 100% !important;
  padding-top: 105px;
  padding-bottom: 105px;
  background-color: white !important;
  border-width: 2px !important;
  margin: 0 !important;
}

.profile-avatar-uploader *,
.profile-bg-uploader * {
  cursor: pointer;
}
