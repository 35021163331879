@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.button {
  width: 40px;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  :global {
    .ant-badge-dot {
      border: 3px solid white;
      box-sizing: content-box;
    }
  }
}