@import "../../styles/mixins";
@import "../../styles/fonts";
@import "../../styles/tmp";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/animations";

.contentWrapper {
  width: 100%;

  .content {
    max-width: 436px;
    width: 100%;
    margin: 0 auto;
  }
}

.notSelectedType {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.subtitle {
  font-size: 12px;
  padding-left: 16px;
}