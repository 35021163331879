$fontSizeBase: 16px;
$fontSizeSm: 14px;

$cardPadding: 16px;

$boxShadowLg: 0 16px 25px -25px #000;
$boxShadowImageGrid: 0 8px 6px -6px rgba(58,46,68,.05);
$boxShadowPost: rgba(0, 0, 0, 0.09) 12px 0px 30px 0px;
$boxShadowHeader: 0 0 15px rgba($clr-gray-750, 0.32);
$boxShadowPost: 0 12px 30px 0 rgba(0, 0, 0, 0.09);
$boxShadowGalleryIcon: -4px -4px 11.4px 0px rgba(0, 0, 0, 0.25);
$boxShadowSider: rgba(0, 0, 0, 0.09) 12px 0px 30px 0px;
