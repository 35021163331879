@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.preview {
  &Wrapper {
    z-index: 3;
    width: fit-content;
    max-width: 500px;
    height: 100%;
  }

  &ImageWrapper {
    width: $galleryThumbWidth;
    height: $galleryThumbHeight;
    padding: 0 6px;
    cursor: pointer;

    > img {
      width: $galleryThumbWidth;
      height: $galleryThumbHeight;
      object-fit: cover;
      border-radius: 8px;
      box-shadow: 0 0 7.694px 0 rgba(0, 0, 32, 0.24);

      &:hover {
        box-shadow: 0 0 7.694px 0 rgba(0, 0, 32, 0.64);
      }


    }
  }


}

.sliderWrapper {
  width: 100%;
  height: 100%;

  > div {
    width: 100%;
    height: 100%;
  }

  .slick {
    :global {
      .slick-list {
        height: 100%;
        overflow: auto;
      }
    }
  }
}

.modal {
  top: 0;
  left: 0;
  z-index: 4;
  width: 100vw;
  height: 100vh;
  display: flex;
  position: fixed;
  color: $clr-black-500;
  flex-direction: column;
  background-color: $clr-black-500;

  &Header {
    height: $heightHeader;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 6;
    padding: 8px 16px;
    position: absolute;
    background: rgba(24, 24, 24, 0.7);
  }

  &Footer {
    gap: 4px;
    bottom: 0;
    z-index: 5;
    width: 100%;
    display: none;
    max-width: 100vw;
    padding: 4px 16px;
    position: absolute;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    background: $clr-black-500;
    background: linear-gradient(0deg, $clr-black-500 0%, transparent 100%);

    @include minw($sm-tab) {
      display: flex;
      gap: 30px;
      height: 90px;
      flex-direction: row;
      align-items: center;
      justify-content: center;
    }
  }

  &Controls {
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: center;

    @include maxw($sm-tab) {
      flex-direction: row-reverse;
      justify-self: flex-start;
    }
  }

  &CounterEmpty {
    display: none;
    width: 36px;
    margin-left: 16px;

    @include minw($sm-tab) {
      display: block;
    }
  }

  &Thumbs {
    display: flex;
    position: relative;
    align-items: center;

    @include maxw($sm-tab) {
      margin-left: auto;
      margin-right: auto;
    }

    &Prev {
      top: 4px;
      left: 6px;
      z-index: 5;
      opacity: 0;
      width: 88px;
      height: 66px;
      display: flex;
      cursor: pointer;
      position: absolute;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      background-color: rgb(0 0 0 / 65%);

      &:hover {
        opacity: 1;
      }
    }

    &Next {
      top: 4px;
      right: 6px;
      z-index: 5;
      opacity: 0;
      width: 88px;
      height: 66px;
      display: flex;
      cursor: pointer;
      position: absolute;
      border-radius: 8px;
      align-items: center;
      justify-content: center;
      background-color: rgb(0 0 0 / 65%);

      &:hover {
        opacity: 1;
      }
    }

    &List {
      gap: 12px;
      display: flex;
      align-items: center;
    }
  }

  &Counter {
    user-select: none;
    font-size: 16px;
    font-weight: 400;
    line-height: 27px;
    font-style: normal;
    margin: 0 16px 0 0;
    white-space: nowrap;
    color: $clr-white-500;
    min-width: 36px;

    @include maxw($sm-tab) {
      display: none;
      margin: 0 0 0 16px;
    }
  }

  &Close {
    top: 0;
    right: 0;
    width: 56px;
    height: 56px;
    display: flex;
    position: fixed;
    cursor: pointer;
    align-items: center;
    justify-content: center;

    &:hover {
      background-color: rgb(32 32 32 / 40%);
    }
  }
  &ImageWrap {
    width: 100%;
    height: 100vh !important;
    overflow: hidden;
    position: relative;
    align-items: center;
    object-fit: contain;
    justify-content: center;
    display: flex !important;

    img, svg {
      max-width: 100%;
      max-height: 100%;
      pointer-events: unset !important;
    }
  }

  &Blur {
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    opacity: 0.65;
    position: absolute;
    transform: scale(1.2);
    background-size: cover;
    background-repeat: no-repeat;
    filter: blur(40px);
    -webkit-filter: blur(40px);
    -moz-filter: blur(40px);
    -o-filter: blur(40px);
    -ms-filter: blur(40px);
  }
}

.navigationButton {
  border: 0;
  z-index: 2;
  width: 56px;
  height: 200px;
  display: flex;
  cursor: pointer;
  position: absolute;
  align-items: center;
  color: $clr-white-500;
  top: calc(50% - 100px);
  justify-content: center;

  @media (hover: hover) {
    &:hover {
      background-color: rgb(32 32 32 / 40%);
    }
  }
}


:global {
  .thumbs-slider {
    .slick-active.slick-current img {
      outline: 4px solid $clr-primary-500;
    }
  }
}