@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.noPosts {
  margin-top: 46px;
  text-align: center;

  &Title {
    font-size: 16px;
    font-weight: 400;
    line-height: 22px;
    font-style: normal;
    text-align: center;
  }
  &Image {
    width: 210px;
    @include minw($mob) {
      width: 450px;
    }
  }
}
