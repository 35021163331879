@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  gap: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  @include maxw($sm-tab) {
    gap: 8px;
    flex-direction: column;
    align-items: flex-start;
  }

  .settings {
    display: flex;
    gap: 6px;

    @include minw($sm-tab) {
      gap: 12px;
    }

    .button {
      width: 40px;
      height: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      border: 1px solid $clr-gray-600;
      background-color: #feffff;
      padding: 0;

      svg {
        width: 20px;
      }
    }

    .icon {
      transition: 0.2s ease-in-out;

      &.paperClip {
        fill: $clr-gray-800;
      }

      &.messageQuestion {
        stroke: $clr-gray-800;
      }
    }
  }

  .buttons {
    gap: 16px;
    display: flex;

    .buttonItem {
      white-space: nowrap;
      min-width: 40px;

      @include maxw($sm-tab) {
        flex: 1;
      }
    }

    @include maxw($sm-tab) {
      width: 100%;
      gap: 8px;
    }
  }
}

.buttonCancel {
  @include maxw($mob) {
    background-color: white;
    border: 1px solid $clr-gray-600;
  }
}

.buttonSubmit {
  @include maxw($mob) {
    flex: 1;
  }
}
