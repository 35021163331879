@import "../../styles/mixins";
@import "../../styles/fonts";
@import "../../styles/tmp";
@import "../../styles/colors";
@import "../../styles/variables";
@import "../../styles/typography";
@import "../../styles/animations";

.backButtonWrap {
  height: 0;
  top: 102px;
  left: -64px;
  position: sticky;
}

.backButtonIcon {
  stroke: black;
  fill: black;
}

.wrapperCentered {
  display: flex;
  padding: 30px 0;
  justify-content: center;
}

.pageWrap {
  position: relative;
  @include minw($sm-tab) {
    padding: 0 0 16px;
  }
}

.feedWrap {
  position: relative;
  min-height: 150px;
  background-color: $clr-white-500;
  box-shadow: 0 12px 30px 0 rgba($clr-black-500, 0.09);
  @include minw($sm-tab) {
    border-radius: $borderRadiusMd;
  }
}

.loaderWrapper {
  height: 150px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.infinitiLoaderWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10px 0;
}

.commentsWrap {
  display: flex;
  flex-direction: column;
  gap: 12px;
  padding-bottom: 6px;
}
