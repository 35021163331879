@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";


.row {
  margin-bottom: 16px;
  .subtitle {
    font-weight: 500;
    color: $clr-gray-850;
  }

}
