@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  margin-bottom: 14px;

  &Comment {
    margin-bottom: 4px;
  }
}

.gridContainer {
  position: relative;
  gap: 4px;
  display: flex;

  &Comment {
    margin: 6px 0 0 !important;
  }
}

.imageWrapper {
  position: relative;
  width: 100%;
  overflow: hidden;
  border: 1px solid #e7edf2;
  border-radius: $borderRadiusMd;
  box-shadow: $boxShadowImageGrid;
  cursor: pointer;

  img {
    border-radius: $borderRadiusMd;
  }

  :global {
    .ant-skeleton-image {
      aspect-ratio: 16 / 10;
    }
  }
}

.item {
  position: relative;
  width: 100%;
}

.image {
  width: 100%;
  height: auto;
  object-fit: cover;
  object-position: center;
  aspect-ratio: 16/10;
}

.showAll {
  flex-direction: column;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}

.gridFooter {
  position: relative;
  display: flex;
  justify-content: flex-end;
  margin-top: 14px;

  &:after {
    left: 0;
    bottom: 17.5px;
    z-index: 0;
    width: 100%;
    height: 1px;
    content: "";
    position: absolute;
    background-color: $clr-primary-100;
  }
}

.notExpandedSingeImage {
  max-height: 280px;
}
