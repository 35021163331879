@import "../../../../../styles/mixins";
@import "../../../../../styles/fonts";
@import "../../../../../styles/tmp";
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/typography";
@import "../../../../../styles/animations";

.styledCrop {
  & > .ReactCrop__crop-selection {
    background-size:
      10px 2px,
      10px 2px,
      2px 10px,
      2px 10px !important;
    background-image: linear-gradient(
        to right,
        transparent 50%,
        $clr-white-500 50%
      ),
      linear-gradient(to right, transparent 50%, $clr-white-500 50%),
      linear-gradient(to bottom, transparent 50%, $clr-white-500 50%),
      linear-gradient(to bottom, transparent 50%, $clr-white-500 50%) !important;
  }

  img {
    width: 100%;
  }
}

.uploadWrapper {
  width: 80px;
  height: 80px;
  overflow: hidden;
  min-height: 80px;
  border-radius: 6px;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &.column {
    width: 100%;
    min-height: 150px;
  }
}

.previousAvatars {
  gap: 16px;
  width: 100%;
  padding: 10px;
  display: flex;
  height: 300px;
  overflow-y: auto;
  overflow-x: auto;
  flex-wrap: nowrap;
  flex-direction: column;

  &.row {
    flex-wrap: wrap;
    flex-direction: row;
  }
}

.avatarsWrapper {
  gap: 16px;
  display: flex;
  overflow: hidden;
  justify-content: center;
}

.fullWidthUpload {
  width: 100% !important;
  :global {
    .ant-upload-select {
      width: 100% !important;
    }
  }
}

.buttonsWrapper {
  gap: 16px;
  width: 100%;
  display: flex;
  justify-content: center;
}

.styledButton {
  width: 150px;
  height: 48px;
}
