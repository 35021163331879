@import "../../../../../styles/mixins";
@import "../../../../../styles/fonts";
@import "../../../../../styles/tmp";
@import "../../../../../styles/colors";
@import "../../../../../styles/variables";
@import "../../../../../styles/typography";
@import "../../../../../styles/animations";

.profileMore {
  display: flex;
  padding: 7px 10px;
  align-items: center;
}
