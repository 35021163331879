@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrap {
  min-height: 100%;
  background: $clr-gray-200;
}

.column {
  max-width: 1220px;
  margin-left: auto;
  margin-right: auto;
}
