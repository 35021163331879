// Gray Colors
$clr-gray-50: #f6f6f7;
$clr-gray-100: #f4f4f4;
$clr-gray-200: #f8f8f8;
$clr-gray-300: #f5f5f5;
$clr-gray-350: #ecf0f9;
$clr-gray-400: #f6f8fc;
$clr-gray-450: #eeeeee;
$clr-gray-500: #dedede;
$clr-gray-550: #e0e0e0;
$clr-gray-600: #d9d9d9;
$clr-gray-650: #33333f;
$clr-gray-700: #cccccc;
$clr-gray-750: #919191;
$clr-gray-800: #717183;
$clr-gray-850: #676767;
$clr-gray-900: #333333;
$clr-gray-950: #717b83;
$clr-gray-1000: #202020;

// Primary Colors
$clr-primary-100: #ecebf2;
$clr-primary-500: #7e7aff;
$clr-primary-600: #7354d1;
$clr-primary-700: #7351db;
$clr-primary-800: #6b50be;
$clr-primary-900: #6341ba;

// Red Colors
$clr-red-500: #f2525b;

// Black Colors
$clr-black-500: #000000;
$clr-black-800: #1c1c1c;
$clr-black-1000: #000020;


// White Colors
$clr-white-100: #fbfbfb;
$clr-white-200: #f7f7f7;
$clr-white-500: #ffffff;

// UI Specific Colors
$clr-box-shadow: rgba($clr-gray-800, 0.32);
$clr-ui-box-shadow: rgba($clr-gray-750, 0.32);
$clr-bg-body: #fcfcfc;
