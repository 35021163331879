@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.register {
  &Button {
    width: 100%;
    height: 46px;
    padding-top: 10px;
    padding-bottom: 10px;
    font-size: 14px;
  }
}
