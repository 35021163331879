@font-face {
  font-family: "Road UI";
  src: url("./../assets/fonts/RoadUI-Regular.eot");
  src:
    local("Road UI Regular"),
    local("RoadUI-Regular"),
    url("./../assets/fonts/RoadUI-Regular.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/RoadUI-Regular.woff2") format("woff2"),
    url("./../assets/fonts/RoadUI-Regular.woff") format("woff"),
    url("./../assets/fonts/RoadUI-Regular.ttf") format("truetype");
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: "Road UI";
  src: url("./../assets/fonts/RoadUI-Medium.eot");
  src:
    local("Road UI Medium"),
    local("RoadUI-Medium"),
    url("./../assets/fonts/RoadUI-Medium.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/RoadUI-Medium.woff2") format("woff2"),
    url("./../assets/fonts/RoadUI-Medium.woff") format("woff"),
    url("./../assets/fonts/RoadUI-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Road UI";
  src: url("./../assets/fonts/RoadUI-SemiBold.eot");
  src:
    local("Road UI Semi Bold"),
    local("RoadUI-SemiBold"),
    url("./../assets/fonts/RoadUI-SemiBold.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/RoadUI-SemiBold.woff2") format("woff2"),
    url("./../assets/fonts/RoadUI-SemiBold.woff") format("woff"),
    url("./../assets/fonts/RoadUI-SemiBold.ttf") format("truetype");
  font-weight: 600;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: "Road UI";
  src: url("./../assets/fonts/RoadUI-ExtraBold.eot");
  src:
    local("Road UI Extra Bold"),
    local("RoadUI-ExtraBold"),
    url("./../assets/fonts/RoadUI-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("./../assets/fonts/RoadUI-ExtraBold.woff2") format("woff2"),
    url("./../assets/fonts/RoadUI-ExtraBold.woff") format("woff"),
    url("./../assets/fonts/RoadUI-ExtraBold.ttf") format("truetype");
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
