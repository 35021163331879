@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.card {
  background-color: $clr-white-500;

  @media (min-width: #{$resolutionsMd}px) {
    border-radius: $borderRadiusMd;
  }

  box-shadow: 0 12px 30px 0 rgba($clr-black-500, 0.09);
}

.header {
  gap: 4px;
  display: flex;
  justify-content: space-between;
  margin-top: -70px;
  padding: 0 $cardPadding 10px;
  flex-wrap: wrap;

  @include minw($sm-tab) {
    flex-wrap: nowrap;
    gap: 16px;
    align-items: flex-start;
    justify-content: flex-start;
    margin-top: -78px;
    position: relative;
  }
}

.photo {
  cursor: pointer;
  width: 140px;
  height: 140px;
  object-fit: cover;
  border-radius: $borderRadiusLg;
  border: 5px solid $clr-white-500;
  margin-left: -5px;

  @include minw($sm-tab) {
    width: 155px;
    height: 155px;
  }
}

.infoTop {
  flex: 1;
  flex-basis: 100%;

  @include minw($sm-tab) {
    padding-top: 96px;
  }
}

.name {
  font-size: 22px;
  font-weight: 500;
  line-height: 24px;
  font-style: normal;
  color: $clr-black-500;

  @include minw($sm-tab) {
    margin-bottom: 8px;
  }
}

.statsTop {
  display: none;

  @include minw($sm-tab) {
    display: block;
  }
}

.statsBottom {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 0 $cardPadding $cardPadding;
}

.actionsTop {
  display: flex;
  padding-top: 86px;

  @include minw($sm-tab) {
    padding-top: 96px;
    gap: 8px;
    order: 2;
  }

  @include maxw($sm-tab) {
    :global {
      .actions-button {
        display: none;
      }
    }
  }
}

.actionsBottom {
  :global {
    .actions-button,.actions-button .ant-btn {
      width: 100%;
    }
  }

  @include minw($sm-tab) {
    display: none;
  }

  @include maxw($sm-tab) {
    :global {
      .ant-space {
        width: 100%;
        padding: 0 $cardPadding $cardPadding;
      }

      .ant-space-item:first-child {
        flex: 1;
      }

      .ant-dropdown-trigger {
        display: none;
      }
    }
  }
}
