@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.attachment {
  &Item {
    overflow: hidden;
    position: relative;
    border-radius: 8px;

    @include maxw($sm-tab) {
      width: fit-content;
      height: 88px;
    }
  }

  &Image {
    pointer-events: none;
    user-select: none;
    width: auto;
    height: 88px;
    object-fit: cover;

    @include maxw($sm-tab) {
      max-width: 128px;
      min-width: 68px;
    }

    @include minw($sm-tab) {
      width: 100%;
      height: 128px;
    }
  }

  &Remove {
    top: 6px;
    right: 6px;
    width: 24px;
    height: 24px;
    opacity: 0.6;
    display: flex;
    cursor: pointer;
    border-radius: 50%;
    position: absolute;
    align-items: center;
    justify-content: center;
    background-color: $clr-white-500;

    &:hover {
      opacity: 1;
      color: $clr-black-500;
    }
  }
}
