@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.list {
  display: flex;
  flex-direction: column;

  @include minw($sm-tab){
    gap: 10px;
    padding-bottom: $cardPadding;
  }
}
