@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.divider {
  font-size: 16px;
  line-height: 18px;
  font-weight: 500;
  padding: 6px;
  text-align: center;
  background-color: $clr-gray-50;
  margin: 6px 0;
}
