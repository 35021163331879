@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";


.container {
  padding: 0 0 0 12px;
  display: flex;
  .coloredLine {
    border-radius: 6px;
    width: 0;
    border-width: 2px;
    border-style: solid;
  }
  .content {
    position: relative;
    width: 100%;
    display: flex;
    align-items: center;
    gap: 8px;
    padding: 5px 12px;
    cursor: pointer;
    color: inherit;
    &:hover {
      color: inherit;
      background-color: $clr-gray-50;
    }
    overflow: hidden;
  }
  .icon {
    @include content-center;
    width: 40px;
    height: 40px;
    background-color: $clr-gray-200;
    border-radius: $borderRadiusMd;
    color: $clr-primary-700;
    align-self: flex-start;
  }
  .header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .body {
    flex: 1;
  }
  .title {
    font-size: 14px;
    font-weight: 500;
    line-height: 20px;

    a {
      color: $clr-black-1000;
    }
  }
  .description {
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: $clr-gray-800;
  }
  .arrow {
    font-size: 22px;
    align-self: flex-start;
  }

  .time {
    font-weight: 400;
    font-size: 12px;
    line-height: 20px;
    color: $clr-gray-800;
    white-space: nowrap;
  }

}
