@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.post {
  &Wrap {
    position: relative;
    opacity: 1;
    padding-left: 12px;
    padding-right: 12px;
    background-color: $clr-white-500;

    .notPublished {
      opacity: 0.4;
    }

    @include minw($sm-tab) {
      border-radius: $borderRadiusMd;
    }
  }

  &Header {
    padding: 12px 0 10px;
  }

  &BodyWrap {
    margin-bottom: 10px;
  }

  &Body {
    text-edge: cap;
    font-weight: 400;
    font-style: normal;
    leading-trim: both;
    font-size: $fontSizeBase;
    margin: 0 !important;
    white-space: break-spaces;
    color: $clr-gray-1000;
  }

  &Author {
    font-weight: 500;
    line-height: 20px;
    font-style: normal;
    color: $clr-gray-900;
    font-size: $fontSizeSm;
    white-space: nowrap;

    &:hover {
      color: $clr-primary-500;
    }
  }

  &DateTime {
    font-weight: 400;
    line-height: 20px;
    font-style: normal;
    color: $clr-gray-750;
    font-size: $fontSizeSm;
    white-space: nowrap;
  }

  &DateTooltipOverlay {
    :global {
      .ant-tooltip-inner {
        color: $clr-gray-1000 !important;
        white-space: nowrap !important;
      }
    }
  }

  &Comments {
    padding: 0;
    margin-top: 4px;

    @include minw($sm-tab) {
      margin-bottom: 6px;
    }
  }
}

.hasShadow {
  @include minw($sm-tab) {
    box-shadow: $boxShadowPost;
  }
  @include maxw($sm-tab) {
    &:not(:last-child) {
      border-bottom: 1px solid $clr-primary-100;
    }
  }
}

.longreadAnchor {
  position: relative;
  top: -70px;
}

.avatar {
  width: 40px;
  height: 40px;
  border-radius: 8px;
  cursor: pointer;
}