@import "../../../../styles/mixins";
@import "../../../../styles/fonts";
@import "../../../../styles/tmp";
@import "../../../../styles/colors";
@import "../../../../styles/variables";
@import "../../../../styles/typography";
@import "../../../../styles/animations";

.searchWrap {
  flex: 1;
  display: flex;
  justify-content: flex-end;

  @include minw($sm-tab) {
    justify-content: flex-start;
  }
}

.searchWrapShow {
  @include maxw($sm-tab) {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 62px;
    padding: 12px;
    background-color: white;
    z-index: 1;
  }
}

.searchButton {
  width: 40px;
  height: 40px;
  display: flex;
  cursor: pointer;
  align-items: center;
  justify-content: center;

  &:hover {
    svg {
      path {
        stroke: $clr-gray-1000;
      }
    }
  }

  @include minw($sm-tab) {
    display: none;
  }
}

.searchInput {
  height: 40px !important;
  border-color: $clr-gray-450 !important;

  @include minw($pc) {
    width: $widthContent !important;
  }

  @include maxw($sm-tab) {
    display: none;
  }
}

.searchInputShow {
  display: flex;
}
