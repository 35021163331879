@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.menu {
  border-inline-end: none !important;
  font-size: 14px;
  font-weight: 500;

  :global {
    .ant-menu-title-content {
      font-size: 16px;
    }

    .ant-menu-item-only-child {
      height: 30px;
      line-height: 30px;
      padding-left: 46px !important;
    }
  }
}
