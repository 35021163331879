@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.wrapper {
  padding-left: 24px;
  padding-right: 24px;
  position: relative;
  background-color: $clr-white-500;
  border: 1px solid $clr-primary-100;
  color: $clr-black-500;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 100%;
  font-size: 16px;
  height: 48px;

  .icon {
    display: flex;
    align-items: center;
    margin-right: auto;
  }

  .text {
    position: absolute;
    left: 50%;
    transform: translateX(-50%);
  }

  &:hover {
    background-color: $clr-gray-200;
  }
}
