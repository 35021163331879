@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.action {
  &Button {
    height: $sizingFooterButton;
    font-size: 14px;
    color: $clr-gray-800;
    background-color: $clr-gray-400;
    border-radius: $borderRadiusFooterButton;
  }

  &Icon {
    width: $sizingFooterButton;
    height: $sizingFooterButton;
    display: flex;
    cursor: pointer;
    align-items: center;
    color: $clr-gray-800;
    justify-content: center;
    background-color: $clr-gray-350;
    border-radius: $borderRadiusFooterButton;
    > svg {
      width: auto;
      height: auto;
      max-width: 18px;
      max-height: 18px;
    }

    &:hover {
      background-color: $clr-primary-100;
      color: $clr-gray-800;
    }
  }

  &Label {
    user-select: none;
    padding: 0 12px 0 8px;
  }
}

.postFooter {
  padding: 0 0 10px 0;
  overflow-x: hidden;
}

.footerButton {
  width: $sizingFooterButton;
  height: $sizingFooterButton;
  display: flex;
  cursor: pointer;
  align-items: center;
  border-radius: $borderRadiusFooterButton;
  color: $clr-gray-800;
  justify-content: center;
  background-color: $clr-gray-350;

  &:hover {
    background-color: $clr-primary-100;
  }
}
