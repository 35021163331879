@import "../../../styles/mixins";
@import "../../../styles/fonts";
@import "../../../styles/tmp";
@import "../../../styles/colors";
@import "../../../styles/variables";
@import "../../../styles/typography";
@import "../../../styles/animations";

.bottomLinks {
  color: $clr-gray-800;
  margin-top: 48px;
  margin-bottom: 40px;
  text-align: center;
}

.link {
  color: $clr-primary-500;
  font-weight: 500;
  text-decoration: underline;
  cursor: pointer;
  &:hover {
    text-decoration: none;
  }
}
